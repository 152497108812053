<template>
<div class="h-slice pb-5"> 
    <div class="container px-3">
        <div class="title montserrat text-wrap text-break pb-4">Technologies & Skills</div>

        <div class="container row ">
            
            <div class="col-12 col-md-6">
                <!-- Section Title -->
                <div class="d-flex heading">    
                <div class="pt-1">
                    <box-icon size="sm" name='terminal' color="#ffffff"></box-icon>
                </div>  
                <div class="b-5 ps-2 sub-title mada">Languages</div>
            </div>
            <!-- Language List -->
            <div class="d-flex pt-3 gap-2 flex-wrap justify-content-left pb-5 ps-0">
                <div class="d-flex">
                    <box-icon size="sm" type='logo' name='javascript' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">Javascript</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" type='logo' name='python' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">Python</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" name='data' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">SQL</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" name='terminal' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">Bash</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" type='logo' name='html5' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">HTML/CSS</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" type='logo' name='java' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">Java</div>
                </div>
                <div class="d-flex ">
                    <box-icon size="sm" type='logo' name='c-plus-plus' color="#ffffff"></box-icon>
                    <div class="ps-2 text heebo">C++</div>
                </div>
            </div>
            </div>

            <div class="col-12 col-md-6">
                <!-- Section Title -->
                <div class="d-flex heading">    
                    <div class="pt-1">
                        <box-icon size="sm" name='bulb' color="#ffffff"></box-icon>
                    </div>  
                    <div class="b-5 ps-2 sub-title mada">Technologies</div>
                </div>
                <!-- Section List -->
                <div class="d-flex pt-3 gap-2 flex-wrap justify-content-left pb-5 ps-3">
                    <div class="d-flex">
                        <box-icon size="sm" type='logo' name='github' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Github</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='nodejs' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">NodeJs</div>
                    </div>
                    <div class="d-flex">
                        <box-icon size="sm" type='logo' name='docker' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Docker</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='aws' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">AWS</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='heroku' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Heroku</div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="d-flex heading">    
                    <div class="pt-1">
                        <box-icon size="sm" name='scatter-chart' color="#ffffff"></box-icon>
                    </div>  
                    <div class="b-5 ps-2 sub-title mada">Data Science</div>
                </div>

                <div class="d-flex p-5 pt-3 gap-3 flex-wrap justify-content-left pb-5 ps-3">
                    <div class="d-flex">
                        <box-icon size="sm" type='logo' name='postgresql' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">PostGresDB</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" name='git-repo-forked' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Kafka</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='firebase' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Firebase</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" name='bar-chart' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Splunk</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='python' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">TensorFlow</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='python' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Pandas & Numpy</div>
                    </div>
                </div>
            </div>

                        
            <div class="col-12 col-md-6">
                <!-- Section Title -->
                <div class="d-flex heading">    
                    <div class="pt-1">
                        <box-icon size="sm" name='book-content' color="#ffffff"></box-icon>
                    </div>  
                    <div class="b-5 ps-2 sub-title mada">Frameworks</div>
                </div>

                <div class="d-flex p-5 pt-3 gap-3 flex-wrap justify-content-left pb-5 ps-3">
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='vuejs' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">Vue</div>
                    </div>
                    <div class="d-flex ">
                        <box-icon size="sm" type='logo' name='react' color="#ffffff"></box-icon>
                        <div class="ps-2 text heebo">React</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <box-icon size="sm" type='logo' name='python' color="#ffffff"></box-icon>

        <box-icon size="sm" type='logo' name='postgresql' color="#ffffff"></box-icon>
        <box-icon size="sm" type='solid' name='data' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='docker' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='aws' color="#ffffff"></box-icon>
        <box-icon size="sm"  name='terminal' color="#ffffff"></box-icon>
        <box-icon size="sm"  name='table' color="#ffffff"></box-icon>
        <box-icon name='bar-chart' type='solid' ></box-icon> 
        <box-icon size="sm" type='logo' name='nodejs' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='vuejs' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='bootstrap' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='firebase' color="#ffffff"></box-icon>
        <box-icon size="sm" type='logo' name='c-plus-plus' color="#ffffff"></box-icon>
                -->

    </div>
</div>
</template>

<script>
</script>

<style scoped>

*{
    color:white;
}

box-icon{
    color:white;
    opacity: 1;
}

.h-slice{
    background-color: var(--third-dark);
    /* background-color: rgb(27, 27, 27); */
}

.heebo{
    font-weight: 400;
}

.mada{
    font-weight: 300;
}

/* .title{
    font-size: 2em;
} */

.sub-title{
    font-size:1.5em;
    padding-bottom: 5px;
}

.d-flex.heading > div{
    border-bottom: 3px solid;
    text-align: left;
}

.d-flex.heading > div:last-child{
    padding-right: 30px;
}

.text{
}

</style>