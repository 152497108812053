<template>
  <div class="pt-5">
    <h1>Soon</h1>
  </div>
</template>

<style>
*{
  color:white
}
</style>